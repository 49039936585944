import clsx from 'clsx';
import {CustomLandingPageContent} from './custom-landing-page-content';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {ButtonProps} from '@common/ui/buttons/button';
import {MixedImage} from '@common/ui/images/mixed-image';
// import {Footer} from '@common/ui/footer/footer';
import {Trans} from '@common/i18n/trans';
import {AdHost} from '@common/admin/ads/ad-host';

import {createSvgIconFromTree} from '@common/icons/create-svg-icon';
import {MenuItemConfig} from '@common/core/settings/settings';
import {Fragment, useState, useEffect, useRef} from 'react';
import {DefaultMetaTags} from '@common/seo/default-meta-tags';
import {useSettings} from '@common/core/settings/use-settings';
import {LandingPageNewLinkForm} from '@app/customlanding/landing-page-new-link-form';
import {useAuth} from '@common/auth/use-auth';
import {LandingPageStats} from '@app/customlanding/landing-page-stats';
import {PricingTable} from '@common/billing/pricing-table/pricing-table';
import {BillingCycleRadio} from '@common/billing/pricing-table/billing-cycle-radio';
import {UpsellBillingCycle} from '@common/billing/pricing-table/find-best-price';
// import { BrowserRouter } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {useProducts} from '@common/billing/pricing-table/use-products';
import './landing-page.css';
import './landing-page-animation.css';
import './landing-page-md-query.css';
// import BootstrapStyles from './bootstrap.min.css';
// import Button from 'react-bootstrap/Button';
import {Offcanvas} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useInView} from 'react-intersection-observer';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import {LandingPageContent} from '../landing/landing-page-content';
// import Form from 'react-bootstrap/Form';
// import 'bootstrap/dist/css/bootstrap.min.css';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import {DarkModeIcon} from '@common/icons/material/DarkMode';
import {LightbulbIcon} from '@common/icons/material/Lightbulb';
import {LightModeIcon} from '@common/icons/material/LightMode';
import {useThemeSelector} from '@common/ui/themes/theme-selector-context';
import {Button} from '@common/ui/buttons/button';
import {LocaleSwitcher} from '@common/i18n/locale-switcher';
// import { useHistory } from 'react-router-dom';
// import styles from './bootstrap.min.css';
// import styles from './bootstrap.module.css';
import {useRecaptcha} from '@common/recaptcha/use-recaptcha';
import {useNavigate} from '@common/utils/hooks/use-navigate';
interface ContentProps {
  content: LandingPageContent;
}
export function CustomLandingPage(data: any) {
  // const history = BrowserRouter;
  // console.log(history);
  // const currentURL: string = history.location.pathname;
  // console.log(currentURL);
  // useEffect(() => {
  //   // Check if the URL contains '/complete-url'
  //   if (window.location.href.includes('/')) {
  //     // Dynamically import Bootstrap CSS if the condition is met
  //     import('./bootstrap.min.css');
        
  //   }
  // }, []);
  const settings = useSettings();
  // console.log(settings);
  // console.log(data);
  const homepage = settings.homepage as {appearance: LandingPageContent};
  // console.log(homepage);
  const showPricing =
    settings.links.homepage_pricing && settings.billing.enable;
  const data1 = data;

 
  const dataimage = (window as any).Laravel.backimage;
  const datalink = (window as any).Laravel.backlink;
// console.log(dataimage);
// console.log(datalink);

  return (
    <Fragment>
 
        <DefaultMetaTags />
        <div className="h-full overflow-y-auto overflow-x-hidden scroll-smooth mycustom_landing">
          <HeroHeader
            body={data1}
            dataimage={dataimage}
            links={datalink}
            content={{content: homepage.appearance}}
          />

          {/* <AdHost slot="landing" className="mb-14 md:mb-70 -mt-30 mx-14" /> */}
          <PrimaryFeatures />
          <Container>
            <div className="h-2 bg-divider mt-80" />
          </Container>
          <SecondaryFeatures />
          <Container className="myborder">
            <div className="h-2 bg-divider mt-md-50 mt-100" />
          </Container>
          <Tabsection />
          <div className="h-0 tab_margin bg-divider mt-md-50 mt-100" />
          {/* <Integrate /> */}
          <div className="h-0 tab_margin bg-divider mt-md-50 mt-100" />
          <Work />
          <div className="h-0 bg-divider mt-md-50 mt-100" />
          <Delightfully />
          {/* <PeopleTalk /> 
        {/* <div className="h-0 bg-divider mt-md-50 mt-100" />
        */}
          {/* <BottomCta content={homepage.appearance} /> */}
          {/* {showPricing && <PricingSection content={homepage.appearance} />} */}

          <Footer />

          {/* <Footer className="landing-container" /> */}
        </div>
      
    </Fragment>
  );
}
function Footer() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const redirectToTwitter = () => {
    window.location.href = 'https://twitter.com';
  };
  const redirectToInsta = () => {
    window.location.href = 'facebook.com';
  };
  return (
    <div>
      <Row className={`${screenWidth < 1024 ? 'd-none' : ''}`}>
        <Col style={{border: '1px solid #EDEDED'}}>
          <div className="footer_col">
            <h2 style={{fontSize: '14px', fontWeight: 700}}>Product</h2>
            <div className="links_list">
              <a href="#features">Features</a>
              <a href="pricing">Pricing</a>
              {/* <a href="#">Download</a> */}
            </div>
          </div>
        </Col>
        <Col style={{border: '1px solid #EDEDED', borderLeft: 'none'}}>
          <div className="footer_col">
            <h2 style={{fontSize: '14px', fontWeight: 700}}>Company</h2>
            <div className="links_list">
              <a href="pages/privacy-policy">Privacy Policy</a>
              <a href="pages/terms-of-service">Terms of Service</a>

              <a href="posts">Blog</a>
              <a href="contact">Contact</a>
            </div>
          </div>
        </Col>
        <Col style={{border: '1px solid #EDEDED', borderLeft: 'none'}}>
          <div className="footer_col">
            <h2 style={{fontSize: '14px', fontWeight: 700}}>Community</h2>
            <div className="links_list">
              <a
                href="https://twitter.com/phlinks1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                href="https://www.instagram.com/phlinks/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
              <a
                href="https://www.pinterest.com/phlinkscom/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pinterest
              </a>
              <a
                href="https://www.facebook.com/people/Phlinks/61552974556445/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://www.linkedin.com/company/phlinks-com/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </div>
          </div>
        </Col>
        <Col style={{border: '1px solid #EDEDED', borderLeft: 'none'}}>
          <div className="footer_col">
            <h2
              className="d-flex"
              style={{fontSize: '22px', fontWeight: '700'}}
            >
              {' '}
              <img
                src="favicon/icon-72x72.png"
                style={{height: '32px'}}
                alt=""
              />
              &nbsp; &nbsp; Phlinks{' '}
            </h2>
            <p
              className="title-dis mt-3 mb-3 fw-bold"
              style={{fontSize: '16px'}}
            >
              Make Money With Your Shortener and BIO Links
            </p>

            <ThemeSwitcher />
            <LocaleSwitcher />
          </div>
        </Col>
      </Row>
      <div className={`${screenWidth < 1024 ? 'my_accordian' : 'd-none'}`}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Product</Accordion.Header>
            <Accordion.Body>
              <div className="links_list ">
                <a href="#features">Features</a>
                <a href="pricing">Pricing</a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Company</Accordion.Header>
            <Accordion.Body>
              <div className="links_list ">
                <a href="pages/privacy-policy">Privacy Policy</a>
                <a href="pages/terms-of-service">Terms of Service</a>

                <a href="posts">Blog</a>
                <a href="contact">Contact</a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Community</Accordion.Header>
            <Accordion.Body>
              <div className="links_list ">
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="footer_col">
          <h2 className="d-flex" style={{fontSize: '22px', fontWeight: '700'}}>
            <img src="favicon/icon-72x72.png" style={{height: '32px'}} alt="" />
            <p
              className="title-dis ms-4 my-auto fw-bold"
              style={{fontSize: '16px'}}
            >
              The Real-time & powerful 3D design for web.
            </p>
            <ThemeSwitcher />
            <LocaleSwitcher />
          </h2>
        </div>
      </div>
    </div>
  );
}
function Delightfully() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const navigate = useNavigate();
  const handleButtonClick = () => {
    // Replace '/register' with the actual URL you want to redirect to
    navigate('/register');
  };
  return (
    <div style={{overflow: 'hidden'}}>
      <Container>
        <h1 className="text-center delight_main_title">
          Phlinks simplifies the process for creators to share multiple parts of
          themselves in one inclusive link.
        </h1>

        <div className="d-flex justify-center">
          <img
            className="main_img"
            src="images/new_landing/Illustration 1 SVG.svg"
            alt=""
            style={{zIndex: '100'}}
          />
        </div>
      </Container>
      <div className="two_bg">
        <div className="light-blue-wall"></div>
        <div className="dark-blue-wall"></div>
      </div>
      <div>
        <Container>
          {/* <div className="circle_1">
            <div className="circle_2">
              <div className="circle_3"></div>
            </div>
          </div> */}
          <div className=" main_text d-flex h-full justify-center">
            <div className=" delight_main_text m-auto">
              <h1
                className={` delight_title ${inView ? 'zoom-in' : 'opacity-0'}`}
              >
                Ready to use Phlinks?
              </h1>
              <p
                className={`mt-4 delight_para ${
                  inView ? 'zoom-in' : 'opacity-0'
                }`}
              >
                Join thousand users and teams in the community
              </p>
              <div className="multi_photo mt-4">
                <img
                  className={`mx-auto ${inView ? 'zoom-in' : 'opacity-0'}`}
                  style={{height: '80px', transform: 'scale(2.5)'}}
                  src="images/new_landing/Illustration SVG 12.svg"
                  alt=""
                />
              </div>
              <Button
                ref={ref}
                style={{zIndex: '10'}}
                variant="raised"
                className={`d-flex relative mt-4  mx-auto rounded-pill text-nowrap download_btn ${
                  inView ? 'zoom-in' : 'opacity-0'
                }`}
                onClick={handleButtonClick}
              >
                Get started
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
function PeopleTalk() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  return (
    <div style={{background: 'rgb(245, 246, 247)', padding: '100px 0px'}}>
      <Container>
        <div className={`${inView ? 'fade-up' : 'opacity-0'}`}>
          <h1 className="text-center people_title">People are talking...</h1>
          <p className="text-center people_para">
            Amet minim mollit non deserunt
          </p>
        </div>

        <div ref={ref} className="h-0 bg-divider mt-md-50 mt-100" />
        <Row className={`${inView1 ? 'fade-up' : 'opacity-0'}`}>
          <Col className="card_column">
            <div className="card_people">
              <img
                src="storage/branding_media/avatar-1.png"
                style={{borderRadius: '50px'}}
                width={64}
                height={64}
                alt=""
              />
              <p className="card_para">
                “It has been an absolute pleasure dealing with Scan during the
                lockdown. Our church began to livestream our services, and Scan
                had a great selection of
              </p>
              <div ref={ref1} className="mt-5">
                <p style={{fontWeight: '700'}}>Emine Isik</p>
                <p>Nursing Assistant</p>
              </div>
            </div>
          </Col>
          <Col className="card_column">
            <div className="card_people">
              <img
                src="storage/branding_media/avatar-6.png"
                style={{borderRadius: '50px'}}
                width={64}
                height={64}
                alt=""
              />
              <p className="card_para">
                “I was also lucky enough to read @Coding_Career early and I was
                thoroughly blown away! It’s the book I wish I had when I started
                my dev career.”
              </p>
              <div className="mt-5">
                <p style={{fontWeight: '700'}}>Emine Isik</p>
                <p>Nursing Assistant</p>
              </div>
            </div>
          </Col>
          <Col className="card_column">
            <div className="card_people">
              <img
                src="storage/branding_media/avatar-5.png"
                style={{borderRadius: '50px'}}
                width={64}
                height={64}
                alt=""
              />
              <p className="card_para">
                “Wow! The immense wealth of knowledge and experience shared in
                “The Coding Career Handbook” cannot be overstated. I love how
                Shawn is able to take his own
              </p>
              <div className="mt-5">
                <p style={{fontWeight: '700'}}>Emine Isik</p>
                <p>Nursing Assistant</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={`${inView2 ? 'fade-up' : 'opacity-0'}`}>
          <Col className="card_column">
            <div className="card_people mt-3">
              <img
                src="storage/branding_media/avatar-4.png"
                style={{borderRadius: '50px'}}
                width={64}
                height={64}
                alt=""
              />
              <p className="card_para">
                “The links to high-value blog posts already make the book worth
                it, never mind the amazing insight and context provided by
                Shawn. Must buy for #CodeNewbies
              </p>
              <div ref={ref2} className="mt-5">
                <p style={{fontWeight: '700'}}>Emine Isik</p>
                <p>Nursing Assistant</p>
              </div>
            </div>
          </Col>
          <Col className="card_column">
            <div className="card_people mt-3">
              <img
                src="storage/branding_media/avatar-3.png"
                style={{borderRadius: '50px'}}
                width={64}
                height={64}
                alt=""
              />
              <p className="card_para">
                “For anyone looking for career advice as a coder but is tired of
                being told they have to do X or never do Y. Shawn is sharing his
                personal experiences and
              </p>
              <div className="mt-5">
                <p style={{fontWeight: '700'}}>Emine Isik</p>
                <p>Nursing Assistant</p>
              </div>
            </div>
          </Col>
          <Col className="card_column">
            <div className="card_people mt-3">
              <img
                src="storage/branding_media/avatar-2.png"
                style={{borderRadius: '50px'}}
                width={64}
                height={64}
                alt=""
              />
              <p className="card_para">
                “I wish I had this book when I was switching jobs. It helps you
                understand what skills you should leverage and really encourages
                you not underlevel yourself!”
              </p>
              <div className="mt-5">
                <p style={{fontWeight: '700'}}>Emine Isik</p>
                <p>Nursing Assistant</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function Work() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Container className="mt-md-5">
        <h1 className="work_maintitle">How does it work?</h1>
        <div className="h-0  work_space bg-divider mt-md-40 mt-100" />
        <Row
          className={` work_outer first_box ${
            inView ? 'fade-up' : 'opacity-0'
          }`}
        >
          <Col className="work_column pl-0">
            <div className="d-flex flex-column h-full justify-content-between work_inner">
              <div>
                <h2 className="work_title">Easy to use</h2>

                <p ref={ref} className="mt-3 work_para">
                  Phlinks offers the simplest solution for creating shortened
                  URLs or optimizing links for bios within minutes.
                </p>
              </div>
              <div>
                <img
                  src="images/new_landing/Illustration 2 SVG.svg"
                  style={{transform: 'scale(1.5)'}}
                />
              </div>
            </div>
          </Col>
          <Col className="work_column2">
            <div className="work_inner1">
              <div className="list_icon d-flex">
                <div className="pe-5 my-auto">
                  <h2 className="list_title">Sign Up</h2>
                  <p className="list_detials">
                    Create your account and gain access to an intuitive platform
                    designed for effortless bio link monetization.
                  </p>
                </div>
                <div
                  className="list_img"
                  style={{background: 'rgb(238, 195 ,181)'}}
                >
                  <img
                    src="images/icon/Sign Up.svg"
                    className="work_small_icon"
                    alt=""
                  />
                </div>
              </div>

              <div className="h-1 bg-divider mt-20 mb-20" />
              <div className="list_icon d-flex">
                <div className="pe-5 my-auto">
                  <h2 className="list_title">Customize Bio Links</h2>
                  <p className="list_detials">
                    Tailor your bio links easily, adding your personal touch to
                    attract audiences while maximizing revenue potential.
                  </p>
                </div>
                <div
                  className="list_img"
                  style={{background: 'rgb(196, 181, 238)'}}
                >
                  <img
                    src="images/icon/Customize Bio Links.svg"
                    className="work_small_icon"
                    alt=""
                  />
                </div>
              </div>
              <div className="h-1 bg-divider mt-20 mb-20" />
              <div className="list_icon d-flex">
                <div className="pe-5 my-auto">
                  <h2 className="list_title">Monitor Performance</h2>
                  <p className="list_detials">
                    Utilize Phlinks' analytics tools to track bio link
                    performance, gaining insights into click-through rates and
                    audience engagement.
                  </p>
                </div>
                <div className="list_img" style={{background: '#B6DFEC'}}>
                  <img
                    className="work_small_icon"
                    src="images/icon/Monitor Performance.svg"
                    alt=""
                  />
                </div>
              </div>
              <div className="h-1 bg-divider mt-20 mb-20" />
              <div className="list_icon d-flex">
                <div className="pe-5 my-auto">
                  <h2 className="list_title">Optimize Strategies</h2>
                  <p className="list_detials">
                    Leverage actionable data obtained from monitoring to refine
                    and optimize your bio link strategies for maximum impact.
                  </p>
                </div>
                <div className="list_img" style={{background: '#CCE7BE'}}>
                  <img
                    className="work_small_icon"
                    src="images/icon/Optimize Strategies.svg"
                    alt=""
                  />
                </div>
              </div>
              <div className="h-1 bg-divider mt-20 mb-20" />
              <div className="list_icon d-flex">
                <div className="pe-5 my-auto">
                  <h2 className="list_title">Maximize Revenue</h2>
                  <p className="list_detials">
                    Implement optimized strategies to capitalize on revenue
                    opportunities and watch your earnings grow effortlessly.
                  </p>
                </div>
                <div
                  className="list_img"
                  style={{background: 'rgb(190,211,231)'}}
                >
                  <img
                    className="work_small_icon"
                    src="images/icon/Maximize Revenue.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="h-0  work_space bg-divider mt-md-40 mt-100" />
        <Row
          className={` work_outer second_box ${
            inView ? 'fade-up' : 'opacity-0'
          }`}
        >
          <Col className="work_column">
            <div className="d-flex flex-column h-full justify-content-between work_inner">
              <div>
                <h2 className="work_title">Set up your Phlinks</h2>

                <Modal className="watch_modal" show={modalIsOpen}>
                  <button className="myclose" onClick={closeModal}>
                    <svg
                      height={32}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 92 92"
                      id="Cross"
                    >
                      <path
                        d="M70.7 64.3c1.8 1.8 1.8 4.6 0 6.4-.9.9-2 1.3-3.2 1.3-1.2 0-2.3-.4-3.2-1.3L46 52.4 27.7 70.7c-.9.9-2 1.3-3.2 1.3s-2.3-.4-3.2-1.3c-1.8-1.8-1.8-4.6 0-6.4L39.6 46 21.3 27.7c-1.8-1.8-1.8-4.6 0-6.4 1.8-1.8 4.6-1.8 6.4 0L46 39.6l18.3-18.3c1.8-1.8 4.6-1.8 6.4 0 1.8 1.8 1.8 4.6 0 6.4L52.4 46l18.3 18.3z"
                        fill="#8b8b8f"
                        className="color000000 svgShape"
                      ></path>
                    </svg>
                  </button>
                  <iframe
                    style={{width: '960px', height: '560px'}}
                    src="https://www.youtube.com/embed/1F3hm6MfR1k?si=FbtUlipqIZTL9JmL&autoplay=1"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </Modal>

                <p ref={ref} className="mt-3 work_para">
                  Discover how to effectively turn your bio links into
                  profit-generating assets. Step into a world of seamless
                  monetization and monitoring by following these five
                  user-friendly steps:
                </p>
              </div>
            </div>
          </Col>
          <Col className="work_column2">
            <div className="work_inner1">
              <div className="list_icon d-flex"></div>
              <img
                src="images/new_landing/Illustration SVG 10.svg"
                style={{transform: 'scale(1.5'}}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
function Integrate() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [inView2, setView2] = useState(false);
  const [inView3, setView3] = useState(false);
  useEffect(() => {
    if (inView1) {
      const timeoutId = setTimeout(() => {
        setView2(true);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inView1]);
  useEffect(() => {
    if (inView2) {
      const timeoutId = setTimeout(() => {
        setView3(true);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inView2]);
  return (
    <div style={{background: 'rgb(245, 246, 247)'}}>
      <Container>
        <Row style={{padding: '100px 0px '}}>
          <Col sm={7} className="work_column2">
            <img
              src="storage/branding_media/iphone.png"
              alt=""
              style={{width: '80%'}}
            />
          </Col>
          <Col
            sm={5}
            className={`justify-content-end work_column2 d-flex ${
              inView ? 'fade-up' : 'opacity-0'
            }`}
          >
            <div className="integrate_box">
              <h1 className="integrate_title">Integrate your 3D designs</h1>
              <br ref={ref} />
              <p className="integrate_para">
                Without any effort, save a ton of time.
              </p>
              <br />
              <br />

              <div ref={ref1} className="icon_list">
                <div
                  className={`h-2 bg-divider ${
                    inView1 ? 'fade-up' : 'opacity-0'
                  }`}
                />
                <div
                  className={`d-flex mt-4 mb-4 ${
                    inView1 ? 'fade-up' : 'opacity-0'
                  }`}
                >
                  <div className="icon_1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={15}
                      height={15}
                      viewBox="0 0 16 24"
                    >
                      <path
                        d="M 16 0 L 16 8 L 8 8 L 0 0 Z"
                        fill="rgb(0, 0, 0)"
                      ></path>
                      <path
                        d="M 0 8 L 8 8 L 16 16 L 0 16 Z"
                        fill="rgb(0, 0, 0)"
                      ></path>
                      <path
                        d="M 0 16 L 8 16 L 8 24 Z"
                        fill="rgb(0, 0, 0)"
                      ></path>
                    </svg>
                  </div>
                  <p className="icon_name my-auto">Framer</p>
                </div>
                <div
                  className={`h-2 bg-divider ${
                    inView2 ? 'fade-up' : 'opacity-0'
                  }`}
                />
                <div
                  className={`d-flex mt-4 mb-4 ${
                    inView2 ? 'fade-up' : 'opacity-0'
                  }`}
                >
                  <div className="icon_2">
                    <img
                      src="storage/branding_media/icons8-w-24.png"
                      style={{transform: 'scale(2.5)'}}
                      alt=""
                    />
                  </div>
                  <p className="icon_name my-auto">Webflow</p>
                </div>
                <div
                  className={`h-2 bg-divider ${
                    inView3 ? 'fade-up' : 'opacity-0'
                  }`}
                />
                <div
                  className={`d-flex mt-4 ${inView3 ? 'fade-up' : 'opacity-0'}`}
                >
                  <div className="icon_3">
                    <img
                      src="storage/branding_media/robot.png"
                      style={{transform: 'scale(2.5)'}}
                      alt=""
                    />
                  </div>
                  <p className="icon_name my-auto">Robot</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
function Tabsection() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Container className="mytabsection">
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3 "
      >
        <Tab eventKey="home" title="URL Shortener">
          <Row>
            <Col className="work_column2">
              <div className="pt-5 pt-md-0">
                <h1 style={{fontSize: '40px', fontWeight: 600}}>
                  Premier Shortener URL
                </h1>
                <p className="my-4 tab1_para">
                  Leverage a free URL shortener to transform lengthy web
                  addresses into short, accessible links, improving shareability
                  and brand identity with custom-branded options. Essential
                  features to consider include customization, analytics,
                  reliability, and user-friendliness for effective link
                  management and a polished professional online presence.
                </p>
                <div style={{width: '35%'}} className="d-flex  two_btn">
                  {/* <Button className="ml-4 text-nowrap download_btn">
                    Launch a demo &nbsp; &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Button> */}
                </div>
              </div>
            </Col>
            <Col className="work_column2">
              <img src="images/new_landing/Illustration SVG 07.svg" alt="" />
            </Col>
          </Row>
        </Tab>
        <Tab
          eventKey="profile"
          title="Links Management
"
        >
          <Row>
            <Col>
              <div className="pt-5">
                <h1 style={{fontSize: '48px', fontWeight: 600}}>
                  Links Management
                </h1>
                <p className="my-4 tab1_para">
                  BIO Link Utility in Online Spaces: These pivotal links on
                  social platforms help navigate followers to various external
                  sites, from products to content. Utilised by influencers and
                  brands, BIO links efficiently direct traffic to online shops,
                  blogs, and more, optimising social media reach and engagement.
                </p>
                <div style={{width: '35%'}} className="d-flex  two_btn">
                  {/* <Button className="ml-4 text-nowrap download_btn">
                    Launch a demo &nbsp; &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Button> */}
                </div>
              </div>
            </Col>
            <Col>
              <img
                src="images/new_landing/Illustration SVG 08.svg"
                style={{transform: 'scale(1.5'}}
                alt=""
              />
            </Col>
          </Row>
        </Tab>
        <Tab
          eventKey="contact"
          title="Links Monetization
"
        >
          <Row>
            <Col>
              <div className="pt-5">
                <h1 style={{fontSize: '48px', fontWeight: 600}}>
                  Links Monetization
                </h1>
                <p className="my-4 tab1_para">
                  Monetize and track your bio links with Phlinks, an intuitive
                  platform that turns links into revenue streams. It offers
                  tools for detailed performance tracking, providing insights
                  into engagement and effectiveness. Elevate your online
                  presence, optimise your strategies, and maximise revenue with
                  Phlinks' easy-to-use interface.
                </p>
                <div style={{width: '35%'}} className="d-flex  two_btn">
                  {/* <Button className="ml-4 text-nowrap download_btn">
                    Launch a demo &nbsp; &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Button> */}
                </div>
              </div>
            </Col>
            <Col>
              <img
                src="images/new_landing/Illustration SVG 09.svg"
                style={{transform: 'scale(1.5'}}
                alt=""
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}
function HeroHeader({
  body,
  dataimage,
  links,
  content,
}: {
  body: any;
  dataimage: any;
  links: any;
  content: ContentProps;
}) {
  // console.log(links);
  // console.log(body);
  // console.log(content)

  const [isMuted, setIsMuted] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  let data = dataimage;
  // Initialize data with an initial value or null

  // Adding a delay of 1000 milliseconds (1 second)
  console.log(dataimage);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  // console.log(imageUrls);
  // let body1;
  // if (body.data !== undefined) {
  //   body1 = body.data;
  //   // Now you can safely use body1 without expecting undefined
  // } else {
  //   // Handle the case when body.body is undefined
  //   console.error('body.body is undefined!');
  // }

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleVideoClick = (link: string) => {
    window.open(link, '_blank');

    setIsMuted(!isMuted);
  };

  const containerStyle = {
    background:
      typeof data === 'string' && (data as string).includes('.mp4')
        ? '#303047'
        : `#303047`,
  };
  const handleImageClick = (link: string) => {
    // Perform the action you want when an image is clicked
    // console.log("asdsadadasd");
    // For example, open the link in a new tab/window
    window.open(link, '_blank');
  };

  const handleClick = () => {
    // Redirect to the /register page
    // history.push('/register');
    window.open('/register', '_blank');
  };
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <>
      {data ? (
        <>
          <header
            className={`relative myheader overflow-hidden isolate ${
              inView ? ' loaded' : ''
            }`}
            style={containerStyle}
          >
            {/* <div className="image">
              <img
                src="images/new_landing/5ziyxuctomdlu7w1irg.svg"
                className="right_header_image"
                alt=""
              />
              <img
                src="images/new_landing/Illustration SVG 02.svg"
                className="left_header_image"
                alt=""
              />
            </div> */}
            {data &&
            typeof data === 'string' &&
            !(data as string).includes('.mp4') ? (
              <Carousel
                style={{
                  // backgroundImage: "url('landing/" + data + "')",
                  // backgroundImage: "url('landing/daniil-silantev-F6Da4r2x5to-unsplash.jpg')",
                  // backgroundAttachment:"fixed",
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  zIndex: '1',
                  cursor: 'pointer',
                  backgroundPosition: 'bottom ',
                  display: imageLoaded ? 'block' : 'none',
                }}
                fade
                indicators={false}
                className="position-absolute"
                // style={{zIndex: '1', cursor: 'pointer'}}
                controls={false}
              >
                {/* {imageUrls.map((item, index) => ( */}
                <Carousel.Item
                  className="d-block w-100"
                  onClick={() => handleImageClick(links)}
                >
                  <img
                    src={`landing/${data}`}
                    style={{objectFit: 'cover'}}
                    className="fade-in" // Assuming your data structure has an 
                    onLoad={handleImageLoad} 
                    alt={`Slide 1`}
                  /> 
                  {/* You can add additional content or styles for each item if needed */}
                </Carousel.Item>
                {/* ))} */}
              </Carousel>
            ) : (
              typeof data === 'string' &&
              (data as string).includes('.mp4') && (
                <video
                  className="position-absolute fade-in"
                  onClick={() => handleVideoClick(links)}
                  autoPlay
                  loop
                  muted={isMuted}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: '1',
                    cursor: 'pointer',
                  }}
                >
                  <source src={`landing/${data}`} type="video/mp4" />
                </video>
              )
            )}
            <div className="flex justify-between sticky_header">
              <div className="flex" style={{height: '40px', zIndex: '2'}}>
                <a href="/">
                  <img
                    src="favicon/icon-72x72.png"
                    style={{height: '40px', zIndex: '2'}}
                    alt=""
                  />
                </a>
              </div>
              <div className="flex my-auto">
                <a
                  href="register"
                  style={{zIndex: '2'}}
                  className="login_button rounded-pill"
                >
                  Register
                </a>
                <a
                  href="login"
                  style={{zIndex: '2'}}
                  className="login_button rounded-pill"
                >
                  Login
                </a>
                <div
                  style={{zIndex: '2'}}
                  className="burger cursor-pointer  my-auto"
                  onClick={handleShow}
                >
                  <div></div>
                  <div></div>
                </div>
                {/* <Button variant="primary" onClick={handleShow}>
              Launch
            </Button> */}

                <Offcanvas show={show} placement="end" onHide={handleClose}>
                  <Offcanvas.Header>
                    <Offcanvas.Title className="flex justify-between position-relative w-full">
                      <img
                        className="pop_icon"
                        src="favicon/icon-72x72.png"
                        style={{height: '52px'}}
                        alt=""
                      />
                      <div
                        className="pop_close position-absolute"
                        onClick={handleClose}
                      >
                        <svg
                          height={32}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 92 92"
                          id="Cross"
                        >
                          <path
                            d="M70.7 64.3c1.8 1.8 1.8 4.6 0 6.4-.9.9-2 1.3-3.2 1.3-1.2 0-2.3-.4-3.2-1.3L46 52.4 27.7 70.7c-.9.9-2 1.3-3.2 1.3s-2.3-.4-3.2-1.3c-1.8-1.8-1.8-4.6 0-6.4L39.6 46 21.3 27.7c-1.8-1.8-1.8-4.6 0-6.4 1.8-1.8 4.6-1.8 6.4 0L46 39.6l18.3-18.3c1.8-1.8 4.6-1.8 6.4 0 1.8 1.8 1.8 4.6 0 6.4L52.4 46l18.3 18.3z"
                            fill="#8b8b8f"
                            className="color000000 svgShape"
                          ></path>
                        </svg>
                      </div>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className={show ? 'pop_links active' : 'pop_links'}>
                      <a href="#features">Features</a>

                      <a href="pricing">Pricing</a>
                      <a href="pages/privacy-policy">Privacy Policy</a>

                      <a href="pages/terms-of-service">Terms of Service</a>
                      <a href="contact">Contact Us</a>
                      <a href="posts">Blog</a>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
            <div className="main-first-section">
              <Container className="py-5 h-full" style={{minHeight: '100%'}}>
                <Row style={{height: '100%'}}>
                  <Col className="position-relative section_col_1">
                    <div
                      style={{opacity: '1'}}
                      className={`w-75 section_1 op ${
                        inView ? 'fade-in-from-left' : ''
                      }`}
                    >
                      <Card className="mycard" style={{zIndex: '2'}}>
                        <Card.Body className="p-4">
                          <Card.Title>
                            <h1
                              className="bold"
                              style={{color: '#F7F7F8', fontWeight: 700}}
                            >
                              Make Money With Your Shortener and BIO Links
                            </h1>
                          </Card.Title>
                          <Card.Text>
                            <h2
                              style={{
                                color: '#F7F7F8',
                                fontSize: '14px',
                                fontWeight: 500,
                              }}
                            >
                              Discover effective ways to monetize bio links and
                              shorten URLs for enhanced engagement and revenue
                              generation in today's digital landscape.
                            </h2>
                          </Card.Text>
                          <LandingPageNewLinkForm content={content.content} />
                          <Card.Text
                            style={{color: '#F7F7F8', fontWeight: 500}}
                          >
                            Or Claim your Link-in-bio Url
                          </Card.Text>
                          <InputGroup className="claim_input my-4">
                            <Form.Control
                              defaultValue="phlinks.com/"
                              placeholder="phlinks.com/"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            <Button
                              // variant="light"
                              variant="raised"
                              onClick={handleClick}
                              className="rounded-pill"
                              id="button-addon2"
                              style={{color: 'blue', background: 'white'}}
                            >
                              Claim BIO
                            </Button>
                          </InputGroup>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col className="position-relative section_col_1">
                    {/* {body1 ? (
                        <div dangerouslySetInnerHTML={{__html: body1}} />
                      ) : (
                        ''
                      )} */}
                  </Col>
                  {/* <div
                    style={{zIndex: '2'}}
                    className={`${
                      screenWidth < 1024
                        ? 'position-absolute  scroll_button '
                        : 'd-none'
                    }`}
                  >
                    <Button className="mr-4 rounded-pill scroll_btn p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="15"
                        fill="currentColor"
                        className="bi bi-arrow-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                        />
                      </svg>
                    </Button>
                    <span
                      style={{
                        color: 'rgb(120, 127, 132)',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      scroll down
                    </span>
                  </div>
                  <div
                    style={{zIndex: '2'}}
                    className={`${
                      screenWidth < 1024
                        ? 'd-none'
                        : 'position-absolute scroll_button'
                    }`}
                  >
                    <Button className="mr-4 rounded-pill scroll_btn p-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="15"
                        fill="currentColor"
                        className="bi bi-arrow-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                        />
                      </svg>
                    </Button>
                    <span
                      style={{
                        color: 'rgb(120, 127, 132)',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      scroll down
                    </span>
                  </div> */}
                </Row>
              </Container>
            </div>
          </header>
        </>
      ) : (
        <span className="loader"></span>
      )}
    </>
  );
}

function PrimaryFeatures() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };
  return (
    <>
      <Container className="py-5 h-auto " id="features">
        <Row>
          <Col lg={6} md={12} className="column_1">
            <div className={`${inView ? 'fade-up' : ''}`}>
              <h1 ref={ref}>
                Your All-in-One Link <br /> Solution
              </h1>
              <h2 className="para_text my-4">The Only Link You'll Ever Need</h2>
              <div className="d-flex  two_btn">
                <p className="my-4 tab1_para">
                  Introducing the definitive link solution, your exclusive
                  destination for consolidating all links. Say farewell to link
                  clutter! Simplify your online presence with our revolutionary
                  service. It's the only link you'll ever need.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12} className="column_2">
            <img
              ref={ref1}
              className={`${inView1 ? 'fade-up mx-auto' : 'mx-auto'}`}
              width={`${screenWidth < 1024 ? 342 : null}`}
              height={`${screenWidth < 1024 ? 941 : null}`}
              src="images/new_landing/Illustration SVG 03.svg"
              // src={`${
              //   screenWidth < 1024
              //     ? 'storage/branding_media/website-pic-mobile.png'
              //     : 'storage/branding_media/website-pic.png'
              // }`}
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

function SecondaryFeatures() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Only trigger the animation once
  });
  const [inView2, setView2] = useState(false);
  const [inView3, setView3] = useState(false);

  useEffect(() => {
    if (inView1) {
      const timeoutId = setTimeout(() => {
        setView2(true);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inView1]);
  useEffect(() => {
    if (inView2) {
      const timeoutId = setTimeout(() => {
        setView3(true);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inView2]);

  return (
    <>
      <Container className="py-5 h-auto ">
        <Row>
          <Col className="py-5 genration_column pb-md-5">
            <div className="">
              <h1
                className={`delight_main_title ${
                  inView ? 'fade-up' : 'opacity-0'
                }`}
              >
                Our Services
              </h1>
            </div>
          </Col>
        </Row>
        <Row ref={ref} className="mt-5 mt-md-2">
          <Col
            ref={ref1}
            className={`work_column2 pb-md-5 ${
              inView1 ? 'fade-up' : 'opacity-0'
            }`}
          >
            <div className="profile_1 profile"></div>
            <div className="mt-3">
              <h2 className="profile_title">URL Shortener</h2>
              <p className=" tab1_para">
                A URL shortener condenses lengthy web addresses into shorter,
                more manageable links. It simplifies sharing and enhances
                readability. It's ideal for social media, marketing, and
                improving user experience.
              </p>
            </div>
          </Col>

          <Col
            className={`work_column2 pb-md-5 ${
              inView2 ? 'fade-up' : 'opacity-0'
            }`}
          >
            <div className="profile_2 profile"></div>
            <div className="mt-3">
              <h2 className="profile_title">Manage Links in Bio</h2>
              <p className=" tab1_para">
                Organize and optimize your bio links. Maximize your online
                presence by managing and customizing links in your bio. This
                creates a seamless user experience and enhances engagement.
              </p>
            </div>
          </Col>
          <Col
            className={`work_column2 pb-md-5 ${
              inView3 ? 'fade-up' : 'opacity-0'
            }`}
          >
            <div className="profile_3 profile"></div>
            <div className="mt-3">
              <h2 className="profile_title">Monetize & Monitor Bio Link</h2>
              <p className=" tab1_para">
                Maximize earnings by monetizing bio links while tracking
                performance. Gain insights, optimize strategies, and enhance
                revenue. Do this by monitoring and monetizing your bio link's
                engagement and clicks.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
function ThemeSwitcher() {
  const {themes} = useSettings();
  const {selectedTheme, selectTheme} = useThemeSelector();
  if (!selectedTheme || !themes?.user_change) return null;

  return (
    <Button
      variant="text"
      className="ps-0"
      startIcon={selectedTheme.is_dark ? <DarkModeIcon /> : <LightbulbIcon />}
      onClick={() => {
        if (selectedTheme.is_dark) {
          selectTheme('light');
        } else {
          selectTheme('dark');
        }
      }}
    >
      {selectedTheme.is_dark ? (
        <Trans message="Dark mode" />
      ) : (
        <Trans message="Light mode" />
      )}
    </Button>
  );
}
