import {Dialog} from '@common/ui/overlays/dialog/dialog';
import {DialogHeader} from '@common/ui/overlays/dialog/dialog-header';
import {DialogFooter} from '@common/ui/overlays/dialog/dialog-footer';
import {DialogBody} from '@common/ui/overlays/dialog/dialog-body';
import {DialogContext} from '@common/ui/overlays/dialog/dialog-context';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {ProgressBar} from '@common/ui/progress/progress-bar';
import {useState, useEffect} from 'react';
import axios from 'axios';
import {Trans} from '@common/i18n/trans';
import {AccountSettingsPanel} from '../account-settings-panel';
import {Button} from '@common/ui/buttons/button';
import {Form} from '@common/ui/forms/form';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {useUpdateAccountDetails} from './update-account-details';
import {useForm} from 'react-hook-form';
import {useId, useContext} from 'react';
import {User} from '../../../user';
import {useDialogContext} from '@common/ui/overlays/dialog/dialog-context';
import {useSettings} from '@common/core/settings/use-settings';


export function DialogPayemnt(props:any) {
    console.log(props);
  const user1 = useSettings();
  const { close, formId } = useDialogContext();
  const [withdrawalAmount, setWithdrawalAmount] = useState(props.data);
  const payment_details = JSON.parse(props.user?.payment_info);

  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
      payment_info: props.user.payment_info,
      account_number: payment_details?.account_number || '',
      bankname: payment_details?.bankname || '',
      user_name: payment_details?.user_name || '',
      ifsc_code: payment_details?.ifsc_code || '',
      paypal_email: payment_details?.paypal_email || '',
      withdraw_request: 0,
    },
  });
  const updateDetails = useUpdateAccountDetails(form);

  const [data, setData] = useState<any>([]);
  const handleClose = () => {

    close();
    // setdialogopen(false);
    setData([]);
  };
  const handleAgree = () => {
    // setdialogopen(false);
    console.log(props.formdata);

    updateDetails.mutate(props.formdata);
    // setData([]);
    close();
    window.location.reload();
  };
  return(
    // <h1>asdasdasda</h1>
  <Dialog>
    <DialogHeader>
      <Trans message="Are you Confirm To Withdraw??" />
    </DialogHeader>
    <DialogBody>
      <div className="flex justify-between ">
        <div>You Want to Withdraw amount</div>
        <div className=""> {parseFloat(withdrawalAmount)}</div>
      </div>
      <div className="flex mt-10 justify-between">
        <div>Withdrawal Charge ({user1.cut_amount_percent} %)</div>
        <div className="text-danger">
          - {(parseFloat(withdrawalAmount) * user1.cut_amount_percent) / 100}
        </div>
      </div>
      <div></div>
      <div className="flex border-t mt-20 justify-between">
        <div className="font-bold">Total Amount Recevied</div>
        <div className="font-bold">
          {parseFloat(withdrawalAmount) -
            (parseFloat(withdrawalAmount) * user1.cut_amount_percent) / 100}
        </div>
      </div>
    </DialogBody>
    <DialogFooter>
      {' '}
      <Button
        type="button"
        variant="flat"
        color="danger"
        onClick={() => {
          close();
          handleClose();
        }}
      >
        Disagree
      </Button>
      <Button
        type="button"
        variant="flat"
        color="primary"
        onClick={handleAgree}
        // autoFocus
      >
        <Trans message="Agree" />
      </Button>
    </DialogFooter>
  </Dialog>
);
}
