import {Trans} from '@common/i18n/trans';
import {Link} from '@app/dashboard/links/link';
import {useSettings} from '@common/core/settings/use-settings';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {ShareLinkButton} from '@app/dashboard/links/sharing/share-link-button';
import {Footer} from '@common/ui/footer/footer';
import {RedirectCountdownButton} from '@app/short-links/renderers/redirect-countdown-button';
import {Button} from '@common/ui/buttons/button';
import {AdHost} from '@common/admin/ads/ad-host';
import {Link as RouterLink} from 'react-router-dom';
import {Display} from '@common/admin/myads/display';
import {useAuth} from '@common/auth/use-auth';
import {ReactElement, useEffect} from 'react';
import React, { useState } from 'react';
interface LinkPageRendererProps {
  link: Link;
}
export function LinkSplashRenderer({link}: LinkPageRendererProps) {
  const { base_url } = useSettings();

  const user1 = link.user?.subscriptions?.length;

  const status = link.ad_status;
  const {user} = useAuth();
  const user_id = user?.id;
  const status1 = link.ad_status_admin;

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const id = link.user_id;
      const response = await fetch(`data2/${id}`);
      const jsonData = await response.json();
      setData(jsonData);
    };

    fetchData();
  }, []);
  return (
    <div className="flex flex-col w-full h-full bg-alt">
      <Navbar
        menuPosition="link-page-navbar"
        rightChildren={<ShareLinkButton link={link} />}
      />
      <div className="container flex-auto flex flex-col items-center justify-center mx-auto px-24">
        <AdHost slot="splash_top" className="mt-20 mb-60 flex-shrink-0" />
        <div className="border rounded md:flex gap-24 p-20 bg-paper flex-shrink-0">
          {/* <img
            src={`${base_url}/${link.hash}/img`}
            alt=""
            className="flex-shrink-0 border w-320 h-240 rounded max-w-full object-contain"
          /> */}
          {user_id == 1 ? (
            status1 == true && user1 !== 0 ? null : (
              <Display creater_id={link.user_id} slot='splash_top' biolink_id={0} link_id={link.id} adclass="mt-20 mb-60 flex-shrink-0" className="flex-shrink-0 border w-320 h-240 rounded max-w-full object-contain das1" />

            )
          ) : user1 !== 0 && status == true && link.user_id !== 1 ? null : (
            <Display creater_id={link.user_id} slot='splash_top' biolink_id={0} link_id={link.id} adclass="mt-20 mb-60 flex-shrink-0" className="flex-shrink-0 border w-320 h-240 rounded max-w-full object-contain dasasda" />
          )}
          <div>
            <h1 className="text-2xl mt-24 md:mt-0 mb-24">
              <Trans message="You are about to be redirected to another page." />
            </h1>
            <div>
              <RedirectCountdownButton
                variant="flat"
                color="primary"
                link={link}
              />
              <Button className="ml-10" elementType={RouterLink} to="/">
                <Trans message="Go back" />
              </Button>
            </div>
            <div className="text-sm text-muted border-t mt-24 pt-24">
              <Trans message="You are about to be redirected to another page. We are not responsible for the content of that page or the consequences it may have on you." />
            </div>
          </div>
        </div>
        <AdHost slot="splash_bottom" className="mt-60 mb-20 flex-shrink-0" />
      </div>
      <Footer className="px-24" />
    </div>
  );
}
