import {useForm} from 'react-hook-form';
import {useId, useContext} from 'react';
import {User} from '../../../user';
import {AccountSettingsPanel} from '../account-settings-panel';
import {Button} from '@common/ui/buttons/button';
import {Form} from '@common/ui/forms/form';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {useUpdateAccountDetails} from './update-account-details';
import {Trans} from '@common/i18n/trans';
import {useUploadAvatar} from '../avatar/upload-avatar';
import {useRemoveAvatar} from '../avatar/remove-avatar';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {ProgressBar} from '@common/ui/progress/progress-bar';
import {useState, useEffect} from 'react';
import axios from 'axios';
// import Dialog from '@mui/material/Dialog';

import {useSettings} from '@common/core/settings/use-settings';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {Dialog} from '@common/ui/overlays/dialog/dialog';
import {DialogHeader} from '@common/ui/overlays/dialog/dialog-header';
import {DialogFooter} from '@common/ui/overlays/dialog/dialog-footer';
import {DialogBody} from '@common/ui/overlays/dialog/dialog-body';
import {DialogContext} from '@common/ui/overlays/dialog/dialog-context';
// Wrap your compon

// import {useDialogContext} from '@common/ui/overlays/dialog/dialog-context';
import {DialogPayemnt} from './dialogpayment';

// import {Modal} from '@common/ui/overlays/modal';
// import {Modal} from 'react-bootstrap';
// import {useAuth} from '@common/auth/use-auth';

interface Props {
  user: User;
}
export function PaymentPanel({user}: Props) {
  const uploadAvatar = useUploadAvatar({user});
  const removeAvatar = useRemoveAvatar({user});
  const formId3 = useId();
  //   const dialogContext = useDialogContext();

  // // if (!dialogContext) {
  // //   // Handle the case where useDialogContext() returns null or undefined
  // //   return null; // or render a loading indicator, an error message, etc.
  // // }

  // const { close, formId } = dialogContext;

  const user1 = useSettings();
  const formId2 = useId();
  const [minprice, setminprice] = useState(0);
  const [percent, setpercent] = useState(0);
  const [withdrawlimit, setWithdrawlimit] = useState(0);

  const [open, setopen] = useState(false);
  const [dialogopen, setdialogopen] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [data, setData] = useState<any>([]);
  const [Transaction, SetTransaction] = useState<any[]>([]);

  const [errorMessage, setErrorMessage] = useState('');
  const handleClickOpen = () => {
    setdialogopen(true);
  };
  // const {close, formId} = useDialogContext();
  // const handleClose = () => {

  //   setdialogopen(false);
  //   setData([]);
  // };
  // const handleAgree = () => {
  //   setdialogopen(false);
  //   console.log(data);

  //   updateDetails.mutate(data);
  //   setData([]);

  //   window.location.reload();
  // };

  // const {close} = useDialogContext();

  // const {
  //   type = 'modal',
  //   dialogProps,
  //   ...contextProps
  // } = useContext(DialogContext);

  // let percent =0 ;
  //  console.log();
  const payment_details = JSON.parse(user?.payment_info);

  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
      payment_info: user.payment_info,
      account_number: payment_details?.account_number || '',
      bankname: payment_details?.bankname || '',
      user_name: payment_details?.user_name || '',
      ifsc_code: payment_details?.ifsc_code || '',
      paypal_email: payment_details?.paypal_email || '',
      withdraw_request: 0,
    },
  });
  const updateDetails = useUpdateAccountDetails(form);

  const id1 = user?.id;
  const handlealltransaction = async () => {
    // console.log("sadsadsa");

    const requestBody = {
      userid: user?.id,
    };
    const response = await axios.post('getalltransactions', requestBody);

    // console.log(response);
    const responseData = response.data.data;
    let total = 0;
    if (responseData) {
      responseData.forEach((item: any) => {
        if (item.status == 0) {
          total += parseFloat(item?.real_amount);
          // console.log(item);
        }
        // You can perform any operations with 'item' here
      });
    }
    setWithdrawlimit(total);
    SetTransaction(responseData);

    // console.log(percent);
    // console.log(responseData);
  };
  const handleclick = async () => {
    // console.log("sadsadsa");

    const requestBody = {
      userid: user?.id,
    };
    const response = await axios.post('getusertotalprice', requestBody);

    // console.log(response);
    const responseData = response.data;
    setminprice(parseFloat(responseData.price));
    const Percent1 =
      100 * (user?.total_balance / responseData.price) > 100
        ? 100
        : 100 * (user?.total_balance / responseData.price);
    setpercent(Percent1);
    // console.log(percent);
    // console.log(responseData);
  };
  useEffect(() => {
    handleclick();
    handlealltransaction();
  }, []);

  const handleChange = (e: {target: {value: any}}) => {
    const value = e.target.value;
    const withdrawalValue = parseFloat(value);
    const minWithdrawalAmount = minprice;

    if (isNaN(withdrawalValue) || withdrawalValue <= 0) {
      setErrorMessage('Please enter a valid positive number for withdrawal');
    } else if (withdrawalValue < minWithdrawalAmount) {
      setErrorMessage(`Minimum withdrawal amount is ${minWithdrawalAmount}`);
    } else if (withdrawalValue > user?.total_balance - withdrawlimit) {
      setErrorMessage(
        `You already sent request for withdrawrequest. Able to withdraw ${(
          user?.total_balance - withdrawlimit
        ).toFixed(2)}`
      );
    } else if (withdrawalValue > parseFloat(user.total_balance)) {
      setErrorMessage('Withdrawal amount exceeds total balance');
    } else {
      setErrorMessage('');
    }
    setWithdrawalAmount(value);
  };

  return (
    <>
      <AccountSettingsPanel
        id={AccountSettingsId.Payment}
        title={<Trans message="Payments " />}
        actions={
          <>
            {/* {open && (
              <Button
                type="submit"
                form={formId2}
                variant="flat"
                color="primary"
              >
                <Trans message="Update" />
              </Button>
            )} */}
          </>
        }
      >
        <div className="flex flex-row justify-between">
          <div className="flex ">Your Earnings</div>
          <div className="flex text-lg font-semibold">
            {' '}
            $ {user?.total_balance}
          </div>
        </div>
        <div className="mt-20 mb-40">
          <p>
            Paid monthly if the total is at least ${minprice?.toFixed(2)} (your
            payout threshold)
          </p>
        </div>
        <ProgressBar value={percent}></ProgressBar>
        <div className="flex flex-row justify-between">
          <div className="flex ">
            You've reached {percent?.toFixed(2)}% of your payment threshold
          </div>
          <div className="flex text-lg font-semibold">
            Payment threshold: ${minprice?.toFixed(2)}
          </div>
        </div>
        {percent == 100 && (
          <Form
            form={form}
            className="flex flex-row my-30 w-full justify-between"
            onSubmit={newDetails => {
              // console.log(newDetails);

              if (payment_details?.account_number == null) {
                setErrorMessage('Please Add Payment Details...!!!');
              } else {
                const data1 = {
                  id: newDetails.id || 0, // Provide a default value of 0 if id is undefined
                  id2: id1,
                  ...newDetails,
                  payment_info: newDetails.payment_info,
                  real_amount: withdrawalAmount,
                  withdraw_request:
                    `${
                      parseFloat(withdrawalAmount) -
                      (parseFloat(withdrawalAmount) *
                        user1.cut_amount_percent) /
                        100
                    }` || 0, // Provide a default empty string if payment_info is undefined
                };
                setData(data1);
                // updateDetails.mutate(data1);
              }
              // console.log(paymentInfoJSON);
            }}
            id={formId3}
          >
            <div className="flex ">
              <FormTextField
                name="withdraw_request"
                label={<Trans message="Enter amount for withdrawal request" />}
                type="number"
                onChange={handleChange}
                errorMessage={errorMessage}
                required
              />
            </div>

            <div className="text-center flex cursor-pointer font-semibold text-md">
              {payment_details?.account_number == null ? (
                <Button
                  type="submit"
                  className="my-auto"
                  variant="flat"
                  color="primary"
                  // onClick={handleClickOpen}
                  // onClick={openDialog}
                  disabled={errorMessage != '' || withdrawalAmount == ''}

                  // disabled={!form.formState.isValid || updatePassword.isLoading}
                >
                  <Trans message="Withdraw Request" />
                </Button>
              ) : (
                <DialogTrigger type="modal">
                  <Button
                    type="submit"
                    className="my-auto"
                    variant="flat"
                    color="primary"
                    // onClick={handleClickOpen}
                    // onClick={openDialog}
                    disabled={errorMessage != '' || withdrawalAmount == ''}

                    // disabled={!form.formState.isValid || updatePassword.isLoading}
                  >
                    <Trans message="Withdraw Request" />
                  </Button>
                  <DialogPayemnt
                    data={withdrawalAmount}
                    formdata={data}
                    user={user}
                  />
                </DialogTrigger>
              )}
            </div>
          </Form>
        )}
      </AccountSettingsPanel>
      <AccountSettingsPanel
        id={AccountSettingsId.Payment}
        title={<Trans message="Payments details" />}
        actions={
          <>
            {open && (
              <Button
                type="submit"
                form={formId2}
                variant="flat"
                color="primary"
              >
                <Trans message="Update" />
              </Button>
            )}
          </>
        }
      >
        <div className="border-2 p-20 mt-20">
          <div>How you get paid </div>
          <div className="flex mt-10 justify-between ">
            <img src={`images/Zx.png`} width={100} height={100} alt="" />
            <p>Add a payment method to receive your earnings</p>
          </div>
          <div className="text-center  cursor-pointer font-semibold text-md">
            <Button
              type="button"
              variant="flat"
              color="primary"
              onClick={() => {
                setopen(!open);
              }}
              // disabled={!form.formState.isValid || updatePassword.isLoading}
            >
              {payment_details?.account_number != null ? (
                <Trans message=" Edit Payment details" />
              ) : (
                <Trans message=" Add Payment details" />
              )}
            </Button>
          </div>
        </div>
        {open && (
          <Form
            form={form}
            className="flex flex-col items-center gap-10"
            onSubmit={newDetails => {
              // console.log(newDetails);
              const paymentInfo = {
                account_number: newDetails?.account_number,
                bankname: newDetails?.bankname,
                ifsc_code: newDetails?.ifsc_code,
                user_name: newDetails?.user_name,
                paypal_email: newDetails?.paypal_email,
              };
              const paymentInfoJSON = JSON.stringify(paymentInfo);
              console.log(paymentInfoJSON);
              const data = {
                id: newDetails.id || 0, // Provide a default value of 0 if id is undefined
                id2: id1,
                ...newDetails,
                payment_info: paymentInfoJSON || '', // Provide a default empty string if payment_info is undefined
              };
              updateDetails.mutate(data);
              window.location.reload();
            }}
            id={formId2}
          >
            <div className="border-2 w-full flex p-20 mt-20">
              <div className="flex-auto w-full">
                <FormTextField
                  className="mb-24"
                  name="bankname"
                  label={
                    <strong>
                      <Trans message="Enter bank name" />
                    </strong>
                  }
                  type="text"
                  required
                />
                <FormTextField
                  className="mb-24"
                  name="account_number"
                  label={<Trans message="Enter account number" />}
                  type="text"
                  required
                />
                <FormTextField
                  className="mb-24"
                  name="ifsc_code"
                  label={<Trans message="Enter IFSC Code" />}
                  type="text"
                  required
                />
                <FormTextField
                  className="mb-24"
                  name="user_name"
                  label={<Trans message="Enter recipient's name" />}
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="border-2 w-full flex p-20 ">
              {/* <div className='bg-divider h-1' /> */}
              <FormTextField
                className="mb-24 w-full "
                name="paypal_email"
                label={
                  <strong>
                    {' '}
                    <Trans message="Enter Paypal Email address" />
                  </strong>
                }
                type="email"
                required
              />
            </div>
          </Form>
        )}
      </AccountSettingsPanel>
    </>
  );
}
