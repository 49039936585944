import {ReactElement} from 'react';
import {GuestRoute} from '../auth/guards/guest-route';
import {RegisterPage} from '../auth/ui/register-page';
import {useSettings} from '../core/settings/use-settings';
import {CustomPageLayout} from '@common/custom-page/custom-page-layout';
import {LoginPageWrapper} from '@common/auth/ui/login-page-wrapper';
import React, { useEffect } from 'react';
interface DynamicHomepageProps {
  homepageResolver?: (type?: string) => ReactElement;
}
export function DynamicHomepage({homepageResolver}: DynamicHomepageProps) {
  const {homepage} = useSettings();
  useEffect(() => {
    // Create a new link element
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.type = 'text/css';

    // Check your condition and set the href accordingly
    const variableValue = 'homepage1'; // Example variableValue
    if (variableValue === 'homepage1') {
      linkElement.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
      linkElement.integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM";
      linkElement.crossOrigin="anonymous";
    } else {
      // If condition is not met, you can set a default CSS file
      linkElement.href = 'path/to/default.css';
    }

    // Get the head element
    const headElement = document.head;

    // Get the children of the head element
    const headChildren = headElement.children;

    // Check if there are more than 10 children in the head
    if (headChildren.length >= 10) {
      // Insert the link element after the 10th child
      headElement.insertBefore(linkElement, headChildren[9].nextSibling);
    } else {
      // If there are less than 10 children, append the link element to the head
      headElement.appendChild(linkElement);
    }

    // Cleanup function to remove the dynamically added link element
    return () => {
      headElement.removeChild(linkElement);
    };
  }, []);
 // Empty dependency array ensures this effect runs only once
  if (homepage?.type === 'loginPage') {
    return (
      <GuestRoute>
        <LoginPageWrapper />
      </GuestRoute>
    );
  }

  if (homepage?.type === 'registerPage') {
    return (
      <GuestRoute>
        <RegisterPage />
      </GuestRoute>
    );
  }

  if (homepage?.type === 'customPage') {
    return <CustomPageLayout slug={homepage.value} />;
  }

  return homepageResolver?.(homepage?.type) || null;
}
