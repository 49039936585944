import {useForm} from 'react-hook-form';
import {useId} from 'react';
import {User} from '../../../user';
import {AccountSettingsPanel} from '../account-settings-panel';
import {Button} from '@common/ui/buttons/button';
import {Form} from '@common/ui/forms/form';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {useUpdateAccountDetails} from './update-account-details';
import {Trans} from '@common/i18n/trans';
import {useUploadAvatar} from '../avatar/upload-avatar';
import {useRemoveAvatar} from '../avatar/remove-avatar';
import {FormImageSelector} from '@common/ui/images/image-selector';
import {FileUploadProvider} from '@common/uploads/uploader/file-upload-provider';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {useAuth} from '@common/auth/use-auth';
import {ProgressBar} from '@common/ui/progress/progress-bar';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import './style.css';
import {useState, useEffect, useRef} from 'react';
import axios from 'axios';

import {parse} from '@app/dashboard/biolink/biolink-editor/content/widgets/spotify-widget/spotify-uri';
// import {useAuth} from '@common/auth/use-auth';

interface Props {
  user: User;
}
export function TransactionPanel({user}: Props) {
  const uploadAvatar = useUploadAvatar({user});
  const removeAvatar = useRemoveAvatar({user});
  const formId = useId();
  const formId2 = useId();
  const [minprice, setminprice] = useState(0);
  const [percent, setpercent] = useState(0);
  const [open, setopen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [Transaction, SetTransaction] = useState<any[]>([]);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // let percent =0 ;
  //  console.log();
  const payment_details = JSON.parse(user?.payment_info);

  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
      payment_info: user.payment_info,
      account_number: payment_details?.account_number || '',
      bankname: payment_details?.bankname || '',
      user_name: payment_details?.user_name || '',
      ifsc_code: payment_details?.ifsc_code || '',
      paypal_email: payment_details?.paypal_email || '',
      withdraw_request: 0,
    },
  });
  const updateDetails = useUpdateAccountDetails(form);

  const id1 = user?.id;
  const handleclick = async () => {
    // console.log("sadsadsa");

    const requestBody = {
      userid: user?.id,
    };
    const response = await axios.post('getalltransactions', requestBody);

    console.log(response);
    const responseData = response.data.data;
    if (responseData) {
      setisLoading(true);
    }
    SetTransaction(responseData);
    // setminprice(parseFloat(responseData.price));
    // const Percent1 =
    //   100 * (user?.total_balance / responseData.price) > 100
    //     ? 100
    //     : 100 * (user?.total_balance / responseData.price);
    // setpercent(Percent1);
    // console.log(percent);
    // console.log(responseData);
  };
  useEffect(() => {
    handleclick();

  }, []);

  const sessionList = (
    <div className="max-h-400 overflow-y-auto">
      {Transaction.map(item => (
        <div className="flex justify-between">
          <div className="flex items-start gap-14 text-sm mb-14">
            <div className="flex-shrink-0 text-muted">
             
              {/* <DeviceIcon device={session.device_type} size="lg" /> */}
            </div>
            <div className="flex-auto">
              <div>
                  $ {item?.real_amount}
                {/* <ValueOrUnknown>{session.platform}</ValueOrUnknown> -{' '} */}
                {/* <ValueOrUnknown>{session.browser}</ValueOrUnknown> */}
              </div>
              <div className="text-xs my-4">
                {/* {session.city}, {session.country} */}
              </div>
              <div className="text-xs">
                    {item?.created_at}
                {/* <IpAddress session={session} /> - <LastActive session={session} /> */}
              </div>
            </div>
          </div>
          <div>
            <div className="flex-shrink-0 text-muted">
              {item?.status == 0 ? (
                <span className="c-pill c-pill--warning">in Pending</span>
              ) : item?.status == 1 ? (
                <span className="c-pill c-pill--success">Success</span>
              ) : (
                <span className="c-pill c-pill--danger">Cancelled</span>
              )}

              {/* <DeviceIcon device={session.device_type} size="lg" /> */}
            </div>
          </div>
        </div>
        // <SessionItem key={session.id} session={session} />
      ))}
    </div>
  );
  return (
    <AccountSettingsPanel
      id={AccountSettingsId.Transaction}
      title={<Trans message="Transaction " />}
      actions={
        <>
          {open && (
            <Button type="submit" form={formId2} variant="flat" color="primary">
              <Trans message="Update" />
            </Button>
          )}
        </>
      }
    >
      <div className="my-10">
        {isLoading == false ? (
          <div className="min-h-60">
            <ProgressCircle isIndeterminate />
          </div>
        ) : (
          sessionList
        )}
      </div>
    </AccountSettingsPanel>
  );
}
