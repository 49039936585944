import React, {useState, useEffect} from 'react';
import {AdHost} from '@common/admin/ads/ad-host';
import {Settings} from '@common/core/settings/settings';
import { useSettings } from '@common/core/settings/use-settings';
interface displayProps {
  className: string;
  slot: keyof Omit<NonNullable<Settings['ads']>, 'disable'>;
  adclass: string;
  link_id: number;
  biolink_id: number;
  creater_id: number;
}

export function Display({
  className,
  slot,
  adclass,
  link_id,
  biolink_id,
  creater_id,
}: displayProps) {
  const [data, setData] = useState<any>([]);
  const settings= useSettings();
  useEffect(() => {
    fetchData();
  }, []);
  console.log(data);
  async function fetchData() {
    try {
      const response = await fetch('data');
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.log('Error fetching random data:', error);
    }
  }

  async function handleDivClick(id: number) {
    try {
      // console.log(id);
      const response = await fetch(
        `insertdata?value=1&id=${id}&link_id=${link_id}&biolink_id=${biolink_id}&user_id=${creater_id}&cost=${data?.cost}&percentage=${settings.percentage_cost}`
      );
      const result = await response.json();
      console.log('Data inserted successfully:', result);
    } catch (error) {
      console.error('Error inserting data:', error);
    }
  }

  return ( 
    <div className="ads1">
      {data.id>0? (
        <HTMLViewer data={data} onClick={() => handleDivClick(data.id)} />
      ) : (
        <AdHost slot={slot} className={adclass} />
      )}
    </div>
  );
}
function HTMLViewer({data, onClick}: {data: any; onClick: () => void}) {
  let textContent = '';
  const videoUrlRegex = /<img src="([^"]+\.mp4)">/g;
  const match = videoUrlRegex.exec(data.body);
  const videoUrl = match ? match[1] : '';
  const textRegex = /<code class="language-html">([^<]+)<\/code>/g;
  const textMatch = textRegex.exec(data.body);
  textContent = textMatch ? textMatch[1] : '';
  const text = textContent;
  let modifiedData = '';
  modifiedData += `<p>${text}</p>`;

  if (videoUrl) {
    modifiedData += `<video controls autoplay muted data-id="${data.id}">
  <source src="${videoUrl}"  type="video/mp4" />
</video>`;
  }
  const imgRegex = /<img[^>]+src="([^"]+\.(jpg|jpeg|png|gif))"/g;
  let imgMatch;
  imgMatch = imgRegex.exec(data.body);
  const imgUrl = imgMatch ? imgMatch[1] : '';

  if (imgUrl) {
    // If there is no video URL, check for images and display them
    modifiedData += `<img data-id="${data.id}" src="${imgUrl}" alt="Image" style="max-height: 200px; max-width: 200px;">`;
  }

  const linkRegex = /<a[^>]+href="([^"]+)"/g;
  let linkMatch;
  linkMatch = linkRegex.exec(data.body);
  const href = linkMatch ? linkMatch[1] : '';
  const containerStyle = {
    cursor: data.url ? 'pointer' : 'default', // Set cursor style based on the presence of URL
  };
  const handleClick = () => {
    if (data.url && data.url !== '#') {
      window.open(data.url, '_blank'); // Open the URL in a new tab/window
    }
    onClick();
  };

  return (
    <div
      className="ads"
      style={containerStyle}
      dangerouslySetInnerHTML={{__html: modifiedData}}
      onClick={() => {
        // onClick();
        handleClick();
      }}
    />
  );
}

export default HTMLViewer;
